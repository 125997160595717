<template>
  <div>
    <label :for="data.id">{{ data.label }} <required :data="data"/></label>
    <textarea :name="data.id" :id="data.id"></textarea>
    <div class="info" v-if="data.description">
      <icon id="circle-info" /> &nbsp;
      <span v-html="data.description.split('\n').join('<br />')"></span>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    required() {
      return import("./required.vue");
    }
  },
  props: {
    data: Object
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.info
  margin-top: 3px
  color: $info
  font-size: 9pt
label
  display: block
  color: $dark-grey
  font-size: 10pt
  margin-bottom: 3px
textarea
  display: block
  width: 100%
  box-sizing: border-box
  padding: 10px
  border: 1px solid $grey
  border-radius: 7px
  height: 150px
  &:focus
    outline: none
</style>
